/* Programme réalisé par evolmu 02.24 
en suivant le tutoriel de Wael Yasmina
https://www.youtube.com/watch?v=e6EkrLr8g_o
 */

import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import {Inspector} from '@babylonjs/inspector';
import { FireProceduralTexture } from '@babylonjs/procedural-textures';


const canvas = document.getElementById('renderCanvas');

const engine = new BABYLON.Engine(canvas);

const createScene =  async function() {
  const scene = new BABYLON.Scene(engine);

  //scene.createDefaultEnvironment();
  //scene.createDefaultCameraOrLight(true,false,true);

  const hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("textures/environment.env", scene);
  scene.environmentTexture = hdrTexture;

  scene.createDefaultLight();
   
  //const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
  //light.intensity = 5;

  //  scene.clearColor = BABYLON.Color3.White();

  //const camera = new BABYLON.UniversalCamera('camera',new BABYLON.Vector3(0,5,-10),scene);
  const camera = new BABYLON.ArcRotateCamera('camera',0,0,5, new BABYLON.Vector3(0,0,0),scene);
  camera.attachControl(true);
  
  camera.angularSensibilityY *= 4;
  camera.angularSensibilityX *= 4;
  
  //camera.inputs.addMouseWheel();
  //camera.setTarget(BABYLON.Vector3.Zero());

  // Vue initiale du la caméra vue de Face
  camera.setPosition(new BABYLON.Vector3(0,0,-5));
  
  // Limitation de la rotation du modèle en X
  //camera.lowerBetaLimit = Math.PI / 4;
  //camera.upperBetaLimit = Math.PI / 2;

  // Limitation du déplacement en Z (zoom)
  camera.lowerRadiusLimit = 2.5;
  camera.upperRadiusLimit = 4;

  // Limitation de la rotation en Y
  // camera.lowerAlphaLimit = 2;
  // camera.upperAlphaLimit = 4;

  
  // mettre plusieur lignes en commentaire ctrl + K et après Ctrl + C
  // enlever le commentaire Ctrl + K et  après CXtrl + U

  /*****************************************************************
  ****************************************************************** */
  
  // const box = new BABYLON.MeshBuilder.CreateBox('myBox',{
  //   size: 0.1,
  //   width: 2,
  //   height: 0.05,
  //   depth: 0.5,
  //   faceColors: [
  //     new BABYLON.Color4(1,0,0,1),
  //     BABYLON.Color3.Green()
  //   ]
  // },scene);


  //var ground = BABYLON.MeshBuilder.CreateGround("ground", {  
  //  width: 40,
  //  height: 40
  //}, scene);
  //ground.position.y = -5;


  // const sphere = new BABYLON.MeshBuilder.CreateSphere('mySphere',{
  //   segments: 50,
  //   diameter: 0.3,
  //   //diameterY: 0.4,
  //     },scene); 

    //const mesh = scene.getMeshByName('VitreRight') ;

    //const pbr = new BABYLON.PBRMaterial("pbr", scene);
    //scene.getMeshByName('VitreRight').material = pbr;
  
    
    //pbr.metallic = 0;
    //pbr.roughness = 0;
  
  //  pbr.subSurface.isRefractionEnabled = true;
  //  pbr.subSurface.indexOfRefraction = 1.5;
  //  pbr.subSurface.tintColor = BABYLON.Color3.Teal();
  
  // pbr.subSurface.thicknessTexture = texture;
  // pbr.subSurface.minimumThickness = 1;
  // pbr.subSurface.maximumThickness = 10;



  // const sphereMaterial = new BABYLON.StandardMaterial();
  // sphere.material = sphereMaterial;

  // //sphereMaterial.diffuseTexture = new BABYLON.Texture('wood_texture.jpg');
  // sphereMaterial.emissiveTexture = new BABYLON.Texture('wood_texture.jpg');
  

  // sphereMaterial.diffuseColor = new BABYLON.Color3(0,1,0);
  // sphereMaterial.specularColor = new BABYLON.Color3(1,0,0);

  // sphereMaterial.ambientColor = new BABYLON.Color3(0,1,1);
  // scene.ambientColor = new BABYLON.Color3(0,1,0.5);

  // sphereMaterial.emissiveColor = new BABYLON.Color3(0,1,0);

  // sphereMaterial.alpha = 0.2;

  // sphereMaterial.wireframe = true;


  
  // const ground = new BABYLON.MeshBuilder.CreateGround('',{
  //   height: 10,
  //   width: 10,
  //   subdivisions: 5,
  //   subdivisionsY: 10,
  // },scene);

  // ground.material = new BABYLON.StandardMaterial();
  // ground.material.wireframe = true;

// const groundFromHM = new BABYLON.MeshBuilder.CreateGroundFromHeightMap('','/snowdon.png',{
// height: 10,
// width: 10,
// subdivisions:50,
// });
  
// groundFromHM.material = new BABYLON.StandardMaterial();
// groundFromHM.material.wireframe = true;

// const fontData = await (await fetch('/Montserrat_Regular.json')).json();
// const text = BABYLON.MeshBuilder.CreateText('','My Text',fontData,{
//   size:2,
//   depth: 0.1,
//   resolution: 64,
// });

// Change la couleur du fond de la scène
scene.clearColor = new BABYLON.Color3(0.98, 0.98, 0.98);



 //with this array we define, which meshes we want to load from the "candle.babylon"-scene
    //use the Inspector in the Playground to check the 'nodes'
    //var matNames = ["Vitre"];



// // * Télécharge le modèle extension .glTF (468)

BABYLON.SceneLoader.ImportMesh('','/','468G.glb',scene);
//BABYLON.Tools.Download(JSON.stringify(delta), "delta.json");

//BABYLON.SceneRecorder.ApplyDelta(delta, scene);


//BABYLON.SceneLoader.ImportMesh("","/","468.babylon",scene); 
//function (newMat) {

 //log the name of the meshes we just imported
      //for(var i = 0; i < newMat.length; i++){
        //    console.log(newMat[i].material);
        
		//scene.getMaterialByName("Vitre").ambientColor = new BABYLON.Color4(1, 1, 1);
		//}
       
        //var mesh= scene.getMeshByName("Corps1.085");
        //console.log("the name is :"+mesh); 
  
 //});
 
// Textures procedurales
//**************************************************************************** */

// const plane = new BABYLON.MeshBuilder.CreatePlane();
// const planeMat = new BABYLON.StandardMaterial();
// plane.material = planeMat;

// const noiseTexture = new BABYLON.NoiseProceduralTexture('noiseTex',256,scene);

//planeMat.emissiveTexture = noiseTexture;
//planeMat.emissiveTexture = new BABYLON.Texture('/Cat.jpg');

// noiseTexture.octaves = 4;
// noiseTexture.persistence = 1.1;
// noiseTexture.animationSpeedFactor = 1.5;

// noiseTexture.getAlphaFromRGB = true;
// planeMat.opacityTexture = noiseTexture;

//const fireTexture = new FireProceduralTexture('fireTexture',256,scene);
//planeMat.emissiveTexture = fireTexture;
//fireTexture.speed = new BABYLON.Vector2(0.5,0.5);

//fireTexture.fireColors = FireProceduralTexture.PurpleFireColors;

// fireTexture.fireColors = [
//   new BABYLON.Color3(0,1,0),
//   new BABYLON.Color3(0,1,1),
//   new BABYLON.Color3(0,1,0),
//   new BABYLON.Color3(0,0,1),
//   new BABYLON.Color3(1,1,0),
//   new BABYLON.Color3(1,0,1),
// ];

//planeMat.opacityTexture = fireTexture;




  return scene 
  };

  const scene = await createScene();

  engine.runRenderLoop(function(){
    scene.render();
  });

  window.addEventListener('resize', function(){
    engine.resize();
  });

  // Ajoute l'inspector sur la scene 
  //Inspector.Show(scene,{});

